import {
  Document,
  DocumentSide,
  UploadedDocumentType,
} from "@gocardless/api/dashboard/types";
import { useVerificationDocumentTokensCreate } from "@gocardless/api/dashboard/verification-document-tokens";
import { HTTPError } from "@gocardless/api/utils/api";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { useAttachDocuments } from "src/common/hooks/requiredActions";
import { errorNotificationHandler } from "src/common/notifications/notificationErrorHandlers";
import { useToastNotification } from "src/common/notifications/useToastNotification";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { captureException } from "src/technical-integrations/sentry/sentry";

export const useUploadDocuments = (
  creditorId: string,
  verificationId: string
) => {
  const [uploading, setUploading] = useState(false);

  const attachDocuments = useAttachDocuments();

  const { triggerSuccessNotification, triggerErrorNotification } =
    useToastNotification();

  const { sendEvent } = useSegment();

  const handleSuccess = () => {
    triggerSuccessNotification({
      title: <Trans>Success!</Trans>,
      message: <Trans>Your document has been uploaded successfully</Trans>,
    });
  };

  const handleError = async (error: HTTPError) => {
    captureException({ error });

    if (error.name === "HTTPError") {
      await errorNotificationHandler(error, triggerErrorNotification);
    }
  };

  const [uploadFile] = useVerificationDocumentTokensCreate();

  const uploadDocuments = async (
    files: File[],
    documentType: UploadedDocumentType
  ) => {
    if (!files.length) {
      return;
    }

    sendEvent(
      TrackingEvent.VERIFICATION_ADDITIONAL_DETAILS_UPLOAD_DOCUMENT_STARTED,
      {
        document_type: documentType,
      }
    );

    setUploading(true);

    try {
      const uploadedFiles = await Promise.all(
        files.map((file) => uploadFile(file, { throwOnError: true }))
      );

      const documents: Document[] = uploadedFiles.map((file) => {
        const token = file.files?.token;

        if (!token) {
          triggerErrorNotification({
            message: (
              <Trans>
                File not uploaded. Please contact support, or try again later.
              </Trans>
            ),
          });

          throw new Error("File not uploaded");
        }

        return {
          token,
          side: DocumentSide.Front,
          document_type: documentType,
        };
      });

      await attachDocuments(creditorId, verificationId, documents);

      handleSuccess();
    } catch (e) {
      await handleError(e as HTTPError);
    } finally {
      setUploading(false);
    }
  };

  return {
    uploadDocuments,
    uploading,
  };
};
